<template>
  <div class="qr">
    <!-- btn -->
    <div
      class="qr-btn"
      @click="layerToggle"
    >
      <i
        class="qr-icon el-icon-plus"
        :class="{rotate: showLayer}"
      />
    </div>
    <!-- layer -->
    <div
      class="qr-layer"
      :class="{hide: !showLayer}"
    >
      <el-row :gutter="10">
        <el-col :span="11">
          <img
            class="qr-pic"
            src="./img/qr.jpg"
          >
        </el-col>
        <el-col :span="13">
          <div class="qr-content">
            <h3 class="qr-name">
              儿科主任医生集团
            </h3>
            <h5 class="qr-phone">
              咨询电话：{{ info.telephone }}
            </h5>
            <h5 class="qr-wx">
              地址：{{ info.WeChat }}
            </h5>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { indexPageTel } from '@/api';

export default {
  data() {
    return {
      showLayer: true,
      info: {}
    };
  },
  created() {
    this.indexPageTel();
  },
  methods: {
    layerToggle() {
      this.showLayer = !this.showLayer;
    },
    indexPageTel() {
      const params = {
        phone: localStorage.getItem('phone')
      };
      indexPageTel(params).then((res) => {
        this.info = res.data || {};
      }, (err) => {
        this.info = {};
      });
    }
  },
};
</script>

<style lang="less" scoped>
.qr{
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  &-btn{
    height: 36px;
    width: 36px;
    box-sizing: border-box;
    border-radius: 36px;
    overflow: hidden;
    background: linear-gradient(270deg, #47C2C2 2.64%, #78DBD9 100%);
    line-height: 44px;
    text-align: center;
    cursor: pointer;
  }
  &-icon{
    color: white;
    font-size: 24px;
    transition: all 0.5s;
    &.rotate{
      transform: rotate(225deg);
    }
  }
  &-layer{
    position: absolute;
    bottom: 36px;
    right: 29px;
    width: 395px;
    height: 189px;
    background: #FFF;
    box-shadow: 0 4px 4px rgba(202, 202, 202, 0.25);
    border-radius: 4px;
    padding: 16px;
    box-sizing: border-box;
    transition: all 0.5s;
    &.hide{
      width: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
  &-pic{
    height: 156px;
    width: auto;
  }
  &-content{
    color: #000;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    width: 192px;
  }
  &-name{
    font-size: 18px;
    margin-top: 4px;
  }
}
</style>
